<template>
  <div class="vue-composer-preview full-flex">
    <div class="full-flex" v-show="errorMsgs.length == 0">
      <v-card class="full-flex" elevation="0">
        <v-tabs v-model="activeTab" style="display: flex; flex: 0 0 auto; align-items: flex-end; border-bottom: 1px solid rgb(233, 233, 233);">
          <v-tab>Preview</v-tab>
          <v-tab>HTML</v-tab>
        </v-tabs>
        
        <v-card-text class="full-flex" style="padding: 0;">
          <v-tabs-items v-model="activeTab">
            <v-tab-item class="tab-item">
              <i-frame :content="renderElement"></i-frame>
            </v-tab-item>
            <v-tab-item class="tab-item">
              <ace-editor :config="previewCodeAceConfig" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>

    <div v-show="errorMsgs.length > 0" class="full-flex render-error">
      <h2>ERROR</h2>
      <div v-for="(errorMsg, index) in errorMsgs" v-bind:key="index" class="full-flex" v-html="errorMsg"></div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import vueTemplate from '@/services/vueTemplate'

export default {
  name: 'vue-composer',
  data: () => ({
    activeTab: null,
    errorMsgs: [],
    previewCodeAceConfig: null,
    previewVueInstance: null,
    ready: false,
    renderElement: null
  }),
  props: {
    config: Object
  },
  watch: {
    config(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  methods: {
    init() {
      if (!this.config || !this.ready) {
        return
      }

      this.destroy()
      
      let config = this.config

      this.previewVueInstance = vueTemplate.createVueInstance(
        config.template,
        config.dataset,
        config.global,
        config.translations,
        config.options,
        {
          warning: (err, vm, info) => {
            this.errorMsgs.push(err.toString() + err.stack)
          },
          error: (err, vm, info) => {
            this.errorMsgs.push(err.toString() + err.stack)
          }
        }
      )
      
      this.renderElement = this.previewVueInstance.$mount().$el

      this.previewCodeAceConfig = {
        autoResize: true,
        beautify: true,
        name: 'codePreview',
        readOnly: true,
        showBeautifyButton: false,
        type: 'html',
        value: this.renderElement.outerHTML
      }
    },

    destroy() {
      this.errorMsgs = []
      this.previewCodeAceConfig = null

      if (this.previewVueInstance) {
        this.previewVueInstance.$destroy()
        this.previewVueInstance = null
      }
    }
  },
  mounted () {
    this.ready = true

    this.init()
  },

  beforeDestroy() {
    this.destroy()
  }
}
</script>

<style lang="scss" scoped>
  .ace-container {
    overflow: auto;
    display: flex;
    flex: 1 0 0;
    background: #f0f0f0;
    border-top: 1px solid rgb(233, 233, 233);
  }
  
  .full-flex {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .iframe {
    border: 1px solid #eee;
  }

  .render-error {
    padding: 20px;
  }

  .show-code {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .vue-composer-preview {
    position: relative;
  }

  .tab-item {
    height: 300px;
  }

  $tabsSidebarWidth: 370px;

  // make tab items stretch to full height
  :deep {
    .data-entry-dialog  {
      overflow-y: visible;
    }

    .sv_body {
      padding: 0 !important;
      border: none !important;
    } 

    .v_window, .v-window__container, .v-window-item, .v-item-group {
      flex: 1 1 0;
      min-height: 0;
      display: flex;
    }
  }

  .tabs-sidebar {
    display: flex;
    flex: 0 0 auto;
    box-shadow: none;
    border-left: 1px solid rgb(233, 233, 233);
  }
</style>